//用于日报弹窗的公共组件
import summaryVue from '#/component/page/float-option/summary-dialog.vue';
const Summary = {};
Summary.install = function(Vue,options){
    const SummaryDialogInstance = Vue.extend(summaryVue);
    let currentSummary;
    const initInstance = ()=>{
        currentSummary = new SummaryDialogInstance();
        let summaryEl = currentSummary.$mount().$el;
        document.body.appendChild(summaryEl);
    }
    Vue.prototype.$summary = {
        showDialog(options){
            console.log(options);
            if(!currentSummary){
                initInstance();
            }
            return currentSummary.showDialog(options);
        }
    }
}
export default Summary;
